import React from "react"
import PropTypes from "prop-types"

import "../styles/hero.css";

const Icon = ({ fill }) => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 378.059 378.059" fill={fill}>
      <g>
    		<path d="M308.79,125.026h-44.96v-26.48c-0.004-3.116-0.892-6.168-2.56-8.8c4.554-6.344,7.015-13.951,7.04-21.76
    			c-0.288-21.294-17.784-38.323-39.078-38.035c-5.08,0.069-10.097,1.141-14.762,3.155c-13.236-17.109-36.833-22.267-56-12.24
    			C145.292-0.023,117.676-6.275,96.787,6.903c-11.065,6.98-18.519,18.456-20.397,31.404c-19.96-7.425-42.16,2.737-49.584,22.697
    			c-1.537,4.131-2.354,8.496-2.416,12.903v78.16c0.117,13.776,10.346,25.369,24,27.2v160c-1.807,19.534,12.564,36.833,32.098,38.64
    			c2.176,0.201,4.366,0.201,6.542,0h138.16c19.534,1.806,36.833-12.564,38.64-32.098c0.201-2.176,0.201-4.366,0-6.542v-4.32h44.96
    			c28.08,0,44.88-20.96,44.88-56v-97.92C353.43,145.826,336.63,125.026,308.79,125.026z M231.065,361.986
    			c-1.948,0.29-3.928,0.29-5.876,0H87.03c-10.881,1.622-21.017-5.883-22.64-16.764c-0.29-1.948-0.29-3.928,0-5.876v-162.56
    			c9.664-4.454,15.894-14.08,16-24.72v-18.4c13.252,4.53,27.909,1.799,38.64-7.2c12.872,21.079,40.395,27.731,61.473,14.859
    			c13.073-7.984,21.151-22.103,21.407-37.419c0-0.96,0-2.24,0-3.52c0-1.28,0-1.68,0-2.48h45.28c0.353,0,0.64,0.286,0.64,0.64v0
    			v240.8C249.452,350.227,241.947,360.364,231.065,361.986z M247.19,81.906h-53.6c-4.418,0-8,3.582-8,8
    			c-0.053,0.532-0.053,1.068,0,1.6c0,0,0,0.56,0,0.8c0.214,2.342,0.214,4.698,0,7.04c0,1.68,0,3.28,0,4.56
    			c-0.314,15.814-13.388,28.38-29.202,28.067c-13.44-0.266-24.886-9.844-27.518-23.027c-0.636-3.429-3.419-6.05-6.88-6.48
    			c-3.362-0.321-6.563,1.504-8,4.56c-5.463,11.345-19.089,16.114-30.434,10.651c-2.098-1.01-4.029-2.336-5.726-3.931
    			c-3.198-3.049-8.262-2.928-11.31,0.27c-1.43,1.5-2.222,3.497-2.21,5.57v32.48c0,6.627-5.373,12-12,12c-6.627,0-12-5.373-12-12
    			v-78.16c0.383-12.498,10.825-22.319,23.323-21.936c6.124,0.188,11.91,2.849,16.038,7.376c2.841,3.384,7.887,3.825,11.271,0.984
    			c2.612-2.192,3.547-5.799,2.329-8.984c-0.826-2.587-1.258-5.284-1.28-8c-0.142-15.817,12.565-28.754,28.381-28.896
    			c12.652-0.114,23.88,8.088,27.619,20.176c1.406,4.189,5.941,6.444,10.13,5.038c0.98-0.329,1.886-0.844,2.67-1.518
    			c12.149-10.128,30.209-8.49,40.337,3.66c1.437,1.724,2.666,3.61,3.663,5.62c1.987,3.946,6.797,5.535,10.743,3.548
    			c0.383-0.193,0.75-0.416,1.097-0.668c3.863-2.798,8.51-4.31,13.28-4.32c12.296-0.133,22.384,9.704,22.56,22
    			C252.415,73.105,250.544,78.038,247.19,81.906z M337.51,279.026c0,14.96-3.76,40-28.88,40h-44.8v-28.56h20.48
    			c17.2,0,27.92-13.04,27.92-34v-53.04c0-20.96-10.72-34-27.92-34h-20.48v-28.4h44.96c25.12,0,28.88,25.12,28.88,40L337.51,279.026z
    			 M263.83,274.546l0.08-89.04h20.48c8,0,11.92,6.08,11.92,18v53.04c0,11.92-4,18-11.92,18H263.83z"/>
      </g>
      <g>
      	<g>
      		<path d="M99.11,164.226c-4.418,0-8,3.582-8,8v131.44c0,4.418,3.582,8,8,8s8-3.582,8-8v-131.44
      			C107.11,167.808,103.528,164.226,99.11,164.226z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M137.11,164.226c-4.418,0-8,3.582-8,8v131.44c0,4.418,3.582,8,8,8s8-3.582,8-8v-131.44
      			C145.11,167.808,141.528,164.226,137.11,164.226z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M175.11,164.226c-4.418,0-8,3.582-8,8v131.44c0,4.418,3.582,8,8,8s8-3.582,8-8v-131.44
      			C183.11,167.808,179.528,164.226,175.11,164.226z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M212.79,164.226c-4.418,0-8,3.582-8,8v131.44c0,4.418,3.582,8,8,8s8-3.582,8-8v-131.44
      			C220.79,167.808,217.208,164.226,212.79,164.226z"/>
      	</g>
      </g>
    </svg>
  );

const Hero = ({ siteTitle }) => (
  <div
    className="hero"
  >
    <div style={{ width: "80px" }}>
      <Icon fill="white"/>
    </div>
    <h1 style={{ textAlign: "center", color: "white", paddingTop: ".5rem" }}>Hofferbar</h1>
  </div>
)

Hero.propTypes = {
  siteTitle: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero;
