import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "../styles/beers.css"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

export default ({ directory }) => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: {relativeDirectory: {eq: "coffees"}}) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="beers">
        { data.allFile.nodes.map((node, i) => (
          <div key={`img-${i}`} className="beer-container">
            <div className="beer">
              <Img
                fluid={node.childImageSharp.fluid}
                alt={node.childImageSharp.fluid.originalName.split('.')[0].split('-').join(' ')}
              />
            </div>
            <div className="hoverPreview">
              <Img
                fluid={node.childImageSharp.fluid}
                alt={node.childImageSharp.fluid.originalName.split('.')[0].split('-').join(' ')}
              />
            </div>
          </div>
        )) }
      </div>
    )}
  />
)
