import React from "react"

import { COLORS } from "../styles/constants"

const SectionHeader = ({ title, description }) => (
  <div>
    <h2 style={{ color: "white" }}>{title}</h2>
    <p style={{ color: COLORS.mediumGray }}>{description}</p>
  </div>
)

export default SectionHeader
