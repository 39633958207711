import React from "react";
import SectionHeader from "./section-header";
import Beers from "./beers";
import Coffees from "./coffees";

const Content = () => (
  <div style={{ padding: "20vh 1rem", textAlign: "center" }}>
    <div className="beerSection">
      <SectionHeader title="The Beers" />
      <Beers/>
    </div>
    
    <div className="beerSection">
      <SectionHeader title="The Coffees/Teas" />
      <Coffees/>
    </div>
  </div>
);

export default Content;
