import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "../styles/beers.css"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

// type SortType = "hottest" | "quantity" | "name"

export default ({ directory }) => {
  const [sortedItems, setSortedItems] = useState([])
  const [sortType, setSort] = useState(undefined)

  const changeSortMethod = (sortType, results) => {
    if (sortType === "quantity") {
      setSort(sortType)
      setSortedItems(results.sort((a, b) => b.quantity - a.quantity))
      return
    }

    if (sortType === "name") {
      setSort(sortType)
      const sorted = results.sort((a, b) => (b.beverage < a.beverage ? 1 : -1))
      setSortedItems(sorted)
      return
    }

    if (sortType === "hotness") {
      setSort(sortType)
      const today = Date.now()
      const sorted = results
        .map((node) => ({
          ...node,
          weeksSince:
            (today - new Date(node.lastStocked).getTime()) /
            (1000 * 3600 * 24 * 7),
        }))
        .map((node) => ({
          ...node,
          hotness: (8 - (node.quantity % 8)) / (4 * node.weeksSince),
        }))
        .sort((a, b) => b.hotness - a.hotness)
      setSortedItems(sorted)
      return
    }
    setSortedItems(results)
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { relativeDirectory: { eq: "beers" } }) {
            nodes {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          googleSheet {
            Sheet1 {
              beverage
              lastStocked
              quantity
              imageName
            }
          }
        }
      `}
      render={(data) => {
        const stock = data.googleSheet.Sheet1.filter(
          (beer) => beer.quantity > 0
        )
        const imageNodes = data.allFile.nodes.map(
          (node) => node.childImageSharp.fluid
        )
        const result = stock.map((beerStock) => {
          const foundBeer = imageNodes.find((image) => {
            return image.originalName === beerStock.imageName
          })
          return { ...beerStock, fluid: foundBeer || ":(" }
        })

        if (sortType === undefined) {
          changeSortMethod("hotness", result)
        }

        return (
          <>
            <div className="sorter">
              <span>
                <input
                  type="radio"
                  name="quantity"
                  value="quantity"
                  id="quantity_radio"
                  checked={sortType === "quantity"}
                />
                <label
                  for="quantity_radio"
                  onClick={() => changeSortMethod("quantity", sortedItems)}
                >
                  Quantity
                </label>
              </span>

              <span>
                <input
                  type="radio"
                  name="name"
                  value="name"
                  id="name_radio"
                  checked={sortType === "name"}
                />
                <label
                  for="name_radio"
                  onClick={() => changeSortMethod("name", sortedItems)}
                >
                  Name
                </label>
              </span>

              <span>
                <input
                  type="radio"
                  name="hotness"
                  value="hotness"
                  id="hotness_radio"
                  checked={sortType === "hotness"}
                />
                <label
                  for="hotness_radio"
                  onClick={() => changeSortMethod("hotness", sortedItems)}
                >
                  Hotness
                </label>
              </span>
            </div>
            <div className="beers">
              {sortedItems.map((node, i) => (
                <div key={`img-${i}`} className="beer-container">
                  <div className="beer">
                    <Img
                      fluid={node.fluid}
                      alt={node.fluid?.originalName
                        ?.split(".")[0]
                        ?.split("-")
                        ?.join(" ")}
                    />
                    <p>In Stock: {node.quantity}</p>
                  </div>
                  <div className="hoverPreview">
                    <Img
                      fluid={node.fluid}
                      alt={node.fluid?.originalName
                        ?.split(".")[0]
                        ?.split("-")
                        ?.join(" ")}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )
      }}
    />
  )
}
