import React from "react"

import SectionHeader from "./section-header";
import Button from "../components/button"

const CallToAction = () => (
  <div style={{ padding: "30vh 1rem", textAlign: "center", contentVisibility: "auto" }}>
    <SectionHeader
      title="About Us"
      description="Hosting Bert's beer fridge since 2020 for those recovering from technology."
    />
    <Button><a style={{ color: "inherit", textDecoration: "None" }} href="sms:17786545958">Text For Info</a></Button>
  </div>
)

export default CallToAction
